import React from "react";

import "./ContributorsPage.css";

const ContributorsPage = () => {
  return (
    <>
      <div className="contributors__container">
        <div className="contributors__top-header">
          <div className="contributors__top-header-left">
            <span>Pages </span> / Contributors
          </div>
        </div>
        <div className="contributors__links-container">
          <div className="contributors__links-header">
            <div className="contributors__links-header__left">
              <h5>Team Hexa Hackers - Smart India Hackathon 2023</h5>
              <span className="contributors__detected-text">
                {/* <span>{contributors.length}</span> currently on network */}
              </span>
            </div>
          </div>
          <div className="contributors__list-container">
            <a
              href="https://www.linkedin.com/in/redomic/"
              className="contributors__list-element"
              id="jones"
            >
              <span>Jones David</span>
            </a>
            <a
              href="https://www.linkedin.com/in/yash-jain-the-insightful-muon/"
              className="contributors__list-element"
              id="yash"
            >
              Yash Jain
            </a>
            <a
              href="https://www.linkedin.com/in/himanshu-kumar-sah-5a9167253/"
              className="contributors__list-element"
              id="himanshu"
            >
              Himanshu Kumar Sah
            </a>
            <a
              href="https://www.linkedin.com/in/shashwatdwivedi2610/"
              className="contributors__list-element"
              id="shashwat"
            >
              Shashwat Dwivedi
            </a>
            <a
              href="https://www.linkedin.com/in/avipsa-maitra-379113266/"
              className="contributors__list-element"
              id="avipsa"
            >
              Avipsa Maitra
            </a>
            <a
              href="https://www.linkedin.com/in/kunwar-singh-bhatia-867607247/"
              className="contributors__list-element"
              id="kunwar"
            >
              Kunwar Singh Bhatia
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributorsPage;
